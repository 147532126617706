import { Component } from 'react'

import CrashPage from './CrashPage'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    }
  }

  componentDidCatch(error, _errorInfo) {
    console.error(error) // eslint-disable-line no-console
    // When required, log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <CrashPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
