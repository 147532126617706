import { Outlet, Route, Routes } from 'react-router-dom'

import AnswersList from './AnswersList'
import CreateAnswer from './CreateAnswer'
import EditAnswer from './EditAnswer'

const Answers = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' index element={<AnswersList />} />
        <Route path='create' element={<CreateAnswer />} />
        <Route path=':id' index element={<EditAnswer />} />
      </Route>
    </Routes>
  )
}

export default Answers
