import * as yup from 'yup'

const guruAuthFormValidationSchema = yup
  .object()
  .shape({
    email: yup.string().trim().required('Email is required').email('Invalid Email'),
    token: yup.string().trim().required('Token is required')
  })
  .required()

export default guruAuthFormValidationSchema
