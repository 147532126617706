import EmptyAnswers from 'Icons/answers-list.svg'

import NoContent from 'Layout/NoContent'

const noContentProps = {
  title: 'Write answers for commonly asked questions',
  description: 'Answers let you create and manage all your simple Q&As in one place using rich text and images',
  ctaButtonText: 'Create a new answer',
  icon: EmptyAnswers,
  ctaTarget: 'create'
}

const NoAnswers = () => {
  return <NoContent {...noContentProps} />
}

export default NoAnswers
