import { useCallback, useState } from 'react'

const useToggleState = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue)

  const toggle = useCallback(() => {
    setValue(!value)
  }, [value])

  return [value, toggle]
}

export default useToggleState
