import { Toaster } from 'react-hot-toast'

const Toast = () => {
  return (
    <Toaster
      containerStyle={{
        position: 'absolute',
        left: 'var(--sidebar-width)'
      }}
      toastOptions={{
        style: {
          zIndex: 200
        }
      }}
    />
  )
}

export default Toast
