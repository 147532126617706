import styles from './Sidebar.module.css'

import Brand from './Brand'
import Nav from './Nav'
import Product from './Product'

const Sidebar = () => {
  return (
    <aside className={styles.SidebarContainer}>
      <Brand />
      <Nav />
      <Product />
    </aside>
  )
}

export default Sidebar
