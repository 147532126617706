import ReactModal from 'react-modal'

export const configureReactModal = () => {
  ReactModal.setAppElement('#__page-root')
  ReactModal.defaultStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: 8,
      outline: 'none',
      padding: '20px',
      height: 'fit-content',
      maxHeight: 600,
      maxWidth: 700
    }
  }
}
