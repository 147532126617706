import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../../../../../../api'

export const otherTicketingApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'otherTicketingApi',
  tagTypes: ['OtherTicketingSystem'],
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({
    getApp: builder.query({
      query: () => '/v1/apps/other-ticketing-system',
      providesTags: ['OtherTicketingSystem']
    }),
    update: builder.mutation({
      query: (url) => {
        return {
          url: '/v1/apps/other-ticketing-system',
          method: 'POST',
          body: {
            new_ticket_url: url
          }
        }
      },
      invalidatesTags: ['OtherTicketingSystem']
    }),
    delete: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/other-ticketing-system',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['OtherTicketingSystem']
    })
  })
})

export const { useGetAppQuery, useUpdateMutation, useDeleteMutation } = otherTicketingApi

export default otherTicketingApi
