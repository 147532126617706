import { createContext, useContext } from 'react'

const AppDetailContext = createContext({
  key: '',
  id: '',
  title: '',
  description: '',
  logo: () => null,
  kbLink: '',
  planCategory: ''
})

export const useAppDetailContext = () => useContext(AppDetailContext)

export default AppDetailContext
